import styled from 'styled-components'
import { ComparePerformance } from './utils'

export const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  svg {
    display: none;
    cursor: pointer;
  }

  &:hover {
    svg {
      display: block !important;
      position: absolute;
      right: 1rem;
    }
  }
`

export const ValuesContainer = styled.div<{ $withLine?: boolean }>`
  color: ${({ theme }) => theme.colors.typePrimary};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: ${({ $withLine }) => ($withLine ? '1rem 2rem' : '1rem')};

  :before {
    content: '';
    width: 1rem;
    height: calc(100% - 1.5rem);
    border-left: 1px solid ${({ theme }) => theme.colors.architecture3};
    border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
    position: absolute;
    bottom: 1.5rem;
    left: 30px;
    display: ${({ $withLine }) => ($withLine ? 'block' : 'none')};
  }
`

export const TypeContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
`

export const Placeholder = styled.div`
  border-radius: 0.25rem;
  border: 1px dashed ${({ theme }) => `${theme.colors.architecture6}4d`};
  background: ${({ theme }) => theme.colors.bgPrimary};
  height: 100%;
  margin: 0 0.25rem;
`

export const ExpandIconContainer = styled.div`
  display: flex;
  align-content: center;
  padding: 1rem 0;
`

export const EmptyContainer = styled.div`
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  margin: 0 -1.25rem;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.typePrimary};
`

export const CompareByText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

export const IntensityValue = styled.div<{
  $performance: ComparePerformance
}>`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  background-color: ${({ theme, $performance }) => {
    if ($performance === 'best') {
      return `${theme.colors.semanticPositive}20`
    } else if ($performance === 'worst') {
      return `${theme.colors.semanticNegative}25`
    } else if ($performance === null) {
      return theme.colors.architecture2
    } else {
      return 'auto'
    }
  }};
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
`
